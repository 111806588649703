<template>
    <div class="text-black">
        <div class="no-data-container" v-if="cartTotal === 0">
            <div class="text-center p-4">
                <img src="/img/cart_empty.png" class="img-fluid " width="40%" alt="" />
                <h4 class="mt-4 font-weight-bold text-dark">{{ $t('noSelectedProduct') }}</h4>
                <span class="text-description">{{ $t('noSelectedProductDespt') }}</span><br>
                <router-link v-if="OrderType !== 'delivery'" to="/dine-in/menulists">
                    <CButton style="width: 200px;" class="btn-color-theme mt-3 ">
                        <strong class="h5"> {{ $t('backToMenu') }} </strong>
                    </CButton>
                </router-link>
            </div>
        </div>

        <div v-else class=" text-left">
            <ul class="list-group list-group-flush mt-3" style="">
                <li class="list-group-item pr-0 pl-0" v-for="(list, index) in cartLists" :key="index">
                    <table style="width: 100%" aria-describedby="">
                        <tr>
                            <td valign="top" align="left">
                                <div v-if="list.remoteImagePath === null ||
            list.remoteImagePath === undefined
            " :style="{
            'background-color': getImgColor(list.indexColor),
            'border-radius': '5px',
            width: '90px',
            height: '90px',
        }"></div>
                                <div v-else :style="{
            'background-image': `url('${noImg}')`,
            'background-size': 'cover',
            'background-position': 'center center',
            'border-radius': '5px',
            width: '90px',
            height: '90px',
        }"><img class="image-inbox" :src="getImageURL(list.remoteImagePath, '100')"
                                        @error="setErrorImg($event, list.remoteImagePath)">
                                </div>
                            </td>
                            <td class="pl-2" style="font-size:medium;" valign="top">
                                <div class="d-flex justify-content-between">
                                    <div>

                                        {{ list.productPLU?.name || '' }}
                                        <br />

                                        {{ list.productPLU?.SKURatio || '' }} {{ list.productPLU?.unit?.name || ''
                                        }}<br>
                                        <div v-if="list.orderedOptions !== undefined">
                                            <div v-if="list.orderedOptions?.length > 0">
                                                <div style=" padding-bottom: 0px" v-for="ordered in list.orderedOptions"
                                                    :key="ordered.objectId" class="p-0">
                                                    <small>
                                                        • {{ ordered.optionItemName }}
                                                    </small>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-if="list.note != ''">
                                            <small class="text-description">
                                                {{ $t('note') }} : {{ list.note }}
                                            </small>
                                        </div>
                                    </div>
                                    <small @click="showModal = true" data-toggle="modal"
                                        :data-target="'#listModal-' + list.id">
                                        <!-- <small @click="deleteAllFromCartList(list)"> -->
                                        <!-- Modal -->
                                        <div v-if="showModal == true" class="modal fade" :id="'listModal-' + list.id">
                                            <div class="modal-dialog" role="document">
                                                <div style="background-color: unset;" class="modal-content">
                                                    <div class="small-modal">
                                                        <div
                                                            class=" d-flex text-dark align-items-center justify-content-center flex-column">
                                                            <i class="fi fi-rr-trash mt-4" style="font-size: 50px;"></i>
                                                            <h4 class="  text-center">{{
            $t('removeSelected') }}</h4>
                                                        </div>
                                                        <div
                                                            class="modal-footer  d-flex align-items-center justify-content-center">
                                                            <button @click="showModal = false" type="button"
                                                                style="width: 40%;" class="btn btn-secondary"
                                                                data-dismiss="modal">{{
            $t('cancel')
        }}</button>
                                                            <button style="width: 40%;" type="button"
                                                                class="btn btn-danger"
                                                                @click="deleteAllFromCartList(list)"
                                                                data-dismiss="modal">{{
            $t('confirm') }}</button>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        <strong style="color: #c73e3e; font-size: 18px">
                                            x
                                        </strong>
                                    </small>
                                </div>

                                <table style="width: 100%" aria-describedby="" class="mt-1">
                                    <tr>
                                        <td style="width: 70%" valign="bottom">
                                            <!-- <h6 class="mb-0">฿{{ summary(list.totalAmount) }}</h6> -->
                                            <h6 class="mb-0 text-grey">฿{{ summary(list.totalAmount) }}</h6>
                                        </td>
                                        <td>

                                            <button v-if="list.quantity == 1"
                                                class="btn btn-outline-light text-color-theme btn-block btn-md add-qty-small"
                                                @click="showModal = true" style="
                         border-radius: 50%;
                      aspect-ratio: 1/1;
                          border: 1px solid #b7b7b7;
                          width: 45px;
                          font-size: 20px;
                        " data-toggle="modal" :data-target="'#listModal-' + list.id">
                                                <strong>-</strong>
                                            </button>
                                            <button v-if="list.quantity > 1"
                                                class="btn btn-outline-light text-color-theme btn-block btn-md add-qty-small"
                                                style="
                         border-radius: 50%;
                      aspect-ratio: 1/1;
                          border: 1px solid #b7b7b7;
                          width: 45px;
                          font-size: 20px;
                        " @click="removeFromCart(list)">
                                                <strong>-</strong>
                                            </button>
                                        </td>
                                        <td style="width: 50%">
                                            <input readonly
                                                class="form-control form-control-md text-center font-weight-bold input-small"
                                                style="border: none; font-size: 20px;background-color: #ffffff;"
                                                v-model.number="list.quantity" pattern="[0-9]*" inputmode="numeric"
                                                type="number" min="0" />
                                        </td>
                                        <td>
                                            <button
                                                class="btn text-color-theme btn-outline-light  btn-block btn-md add-qty-small"
                                                style="
                      border-radius: 50%;
                      aspect-ratio: 1/1;
                          border: 1px solid #b7b7b7;
                          width: 45px;
                          font-size: 20px;
                        " @click="addToCart(list)">
                                                <strong>+</strong>
                                            </button>
                                        </td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                    </table>
                </li>
            </ul>

            <div class="modal fade" id="price-modal">
                <div class="modal-dialog" role="document">
                    <div class="modal-content" style="background-color: unset;">
                        <div class="small-modal" style="background-color: white;">
                            <div class="p-2 pt-3 text-center">
                                <i style="color: var(--bg-color-theme);font-size: 40px;" class="fi fi-rr-info"></i>
                                <h5 class="text-bold">{{ $t('vatNotInclude') }}</h5>
                                <span class="text-description">{{ $t('checkGramdTotalIn') }}<br> "{{ $t('checkOut')
                                    }}"</span>
                                <div class="mt-2 mb-2">
                                    <CButton block data-dismiss="modal" class="btn-color-theme ">{{
            $t('accept') }}</CButton>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <footer v-if="OrderType !== 'delivery'" class="fixed-bottom"
                style="padding: 20px; background-color: #ffffff; box-shadow: 0 -4px 8px rgba(0, 0, 0, 0.05); ">
                <div class="text-description text-center mb-3" style="font-size: large;">{{
                    $t('checkOrderBeforeConfirm')
                    }}<br>{{
                    $t('checkOrderBeforeConfirm2') }}
                </div>
                <div class="row text-dark  pl-2 pr-2">
                    <div class="col">
                        <h5>{{ cartTotal }} {{ $t('item') }}</h5>
                    </div>
                    <div v-if="!shop.isVATIncluded" data-toggle="modal" data-target="#price-modal" class="col">
                        <h5 class="font-weight-bold text-right">฿{{ totalCost }} <i
                                style="color: var(--bg-color-theme);" class="fi fi-rr-info"></i></h5>
                    </div>
                    <div v-else class="col">
                        <h5 class="font-weight-bold text-right">฿{{ totalCost }}</h5>
                    </div>
                </div>
                <router-link to="/dine-in/pending" style="text-decoration: none">
                    <CButton class="btn-selecteditem" size="lg" style="font-size: x-large; color: white;" block>
                        <span>{{ $t('confirmOrder') }} </span>
                    </CButton>
                </router-link>
            </footer>
        </div>


    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import util from '@/util/util'
import order from '@/util/order'
import '@/util/menu.css'
import { CButton } from '@coreui/vue'

export default {
    components: {
        CButton
    },
    data() {
        return {
            cartLists: [],
            cartTotal: 0,
            showModal: false,
        }
    },
    computed: {
        ...mapGetters(['shopLineOA']),
        OrderType() {
            let type = window.location.pathname;
            if (type === '/dine-in/selecteditem') {
                return 'qr';
            } else {
                return 'delivery';
            }
        },
        uid() {
            return `${localStorage.getItem('uid')}`
        },
        shopObjectId() {
            return `${localStorage.getItem('shopObjectId')}`
        },
        shop() {
            return this.shopLineOA.find(
                (detail) => detail.objectId === this.shopObjectId
            )
        },
        shopName() {
            if (this.shop) {
                return this.shop.shopName
            } else {
                return ''
            }
        },
        logo() {
            if (this.shop) {
                return this.shop.remoteImagePath
            } else {
                return ''
            }
        },
        totalCost() {
            let total = this.cartLists.reduce((accum, product) => {
                return accum + product.totalAmount
            }, 0)

            return util.convertCurrency(total)
        },
        tableName() {
            let table = JSON.parse(localStorage.getItem('table'))
            return table.name
        },
        noImg() {
            return process.env.VUE_APP_NOIMAGE
        },
    },
    created() {
        this.cartLists = JSON.parse(sessionStorage.getItem('cart')) || []
        this.getCartTotal()
        window.pageYOffset = 0
    },
    methods: {
        ...util,
        setErrorImg(event, image) {
            if (event.target.src == image) {
                event.target.src = this.noImg
            } else {
                event.target.src = image
            }
        },
        trackButtonClick(buttonLabel) {
            if (typeof window.gtag === 'function') {
                window.gtag('event', 'click', {
                    event_label: buttonLabel,
                });
            } else {
                console.error('gtag is not defined.');
            }
        },
        convertCurrency(num) {
            if (typeof num == 'number') {
                return '(+' + util.convertCurrency(Number(num)) + ')'
            } else {
                return ''
            }
        },
        getCartTotal() {
            let total = this.cartLists.reduce((accum, product) => {
                return accum + product.quantity
            }, 0)

            this.cartTotal = total
        },
        getImgColor(indexColor) {
            return util.generateColor(indexColor)
        },
        goback() {
            window.history.back()
        },
        summary(amount) {
            return util.convertCurrency(amount)
        },
        addToCart(product) {
            this.trackButtonClick('SelectedItem/AddQTY')
            const locationInCart = this.cartLists.findIndex((p) => {
                return p.id === product.id
            })

            this.cartLists[locationInCart].quantity++

            let amount = order.updateAmount(
                this.cartLists[locationInCart],
                this.cartLists[locationInCart].quantity
            )

            this.cartLists[locationInCart].price = amount.price
            this.cartLists[locationInCart].netAmount = amount.amount
            this.cartLists[locationInCart].totalAmount = amount.amount
            this.cartLists[locationInCart].quantitySKU = amount.quantitySKU


            sessionStorage.setItem('cart', JSON.stringify(this.cartLists))
            this.getCartTotal()
        },
        removeFromCart(product) {
            this.trackButtonClick('SelectedItem/RemoveQTY')
            this.showModal = false
            const locationInCart = this.cartLists.findIndex((p) => {
                return p.id === product.id
            })

            let amount = {}

            if (this.cartLists[locationInCart].quantity <= 1) {
                this.cartLists.splice(locationInCart, 1)
            } else {
                this.cartLists[locationInCart].quantity--
                amount = order.updateAmount(
                    this.cartLists[locationInCart],
                    this.cartLists[locationInCart].quantity
                )

                this.cartLists[locationInCart].price = amount.price
                this.cartLists[locationInCart].netAmount = amount.amount
                this.cartLists[locationInCart].totalAmount = amount.amount
                this.cartLists[locationInCart].quantitySKU = amount.quantitySKU
            }

            sessionStorage.setItem('cart', JSON.stringify(this.cartLists))
            this.getCartTotal()
        },
        deleteAllFromCartList(product) {
            this.showModal = false
            const locationInCart = this.cartLists.findIndex((p) => {
                return p.id === product.id
            })
            this.cartLists.splice(locationInCart, 1)
            sessionStorage.setItem('cart', JSON.stringify(this.cartLists))
            this.getCartTotal()

        },
    },
}
</script>

<style scoped>
input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

.modal {
    align-items: center;
    justify-content: center;
    /* height: 35%; */
    width: 80%;
    border-radius: 3%;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1070;
}

.modal-backdrop.show {
    opacity: 0.5;
    z-index: 1000;
}

.modal .modal-body {
    overflow-y: hidden;
}

@media screen and (max-width: 370px) {
    .add-qty-small {
        width: 30px !important;
        height: 30px !important;
        font-size: 10px !important;
        text-align: center;
    }

    .input-small {
        font-size: 10px !important;
    }


}

img.image-inbox {
    position: relative;
    object-fit: cover;
}
</style>
