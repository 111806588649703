<template>
  <div class="text-dark">
    <AppCartLists style="margin-bottom: 250px;margin-top: 40px;"></AppCartLists>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import util from '@/util/util'
import order from '@/util/order'
import '@/util/menu.css'
import AppCartLists from '@/components/AppCartLists'

export default {
  components: {
    AppCartLists
  },
  data() {
    return {
      cartLists: [],
      cartTotal: 0,
      showModal: false,
    }
  },
  computed: {
    ...mapGetters(['shopLineOA']),
    uid() {
      return `${localStorage.getItem('uid')}`
    },
    shopObjectId() {
      return `${localStorage.getItem('shopObjectId')}`
    },
    shop() {
      return this.shopLineOA.find(
        (detail) => detail.objectId === this.shopObjectId
      )
    },
    shopName() {
      if (this.shop) {
        return this.shop.shopName
      } else {
        return ''
      }
    },
    logo() {
      if (this.shop) {
        return this.shop.remoteImagePath
      } else {
        return ''
      }
    },
    totalCost() {
      let total = this.cartLists.reduce((accum, product) => {
        return accum + product.totalAmount
      }, 0)

      return util.convertCurrency(total)
    },
    tableName() {
      let table = JSON.parse(localStorage.getItem('table'))
      return table.name
    },
    noImg() {
      return process.env.VUE_APP_NOIMAGE
    },
  },
  created() {
    this.cartLists = JSON.parse(sessionStorage.getItem('cart')) || []
    this.getCartTotal()

  },
  methods: {
    ...util,
    setErrorImg(event, image) {
      if (event.target.src == image) {
        event.target.src = this.noImg
      } else {
        event.target.src = image
      }
    },
    trackButtonClick(buttonLabel) {
      if (typeof window.gtag === 'function') {
        window.gtag('event', 'click', {
          event_label: buttonLabel,
        });
      } else {
        console.error('gtag is not defined.');
      }
    },
    convertCurrency(num) {
      if (typeof num == 'number') {
        return '(+' + util.convertCurrency(Number(num)) + ')'
      } else {
        return ''
      }
    },
    getCartTotal() {
      let total = this.cartLists.reduce((accum, product) => {
        return accum + product.quantity
      }, 0)

      this.cartTotal = total
    },
    getImgColor(indexColor) {
      return util.generateColor(indexColor)
    },
    goback() {
      window.history.back()
    },
    summary(amount) {
      return util.convertCurrency(amount)
    },
    addToCart(product) {
      this.trackButtonClick('SelectedItem/AddQTY')
      const locationInCart = this.cartLists.findIndex((p) => {
        return p.id === product.id
      })

      this.cartLists[locationInCart].quantity++

      let amount = order.updateAmount(
        this.cartLists[locationInCart],
        this.cartLists[locationInCart].quantity
      )



      this.cartLists[locationInCart].price = amount.price
      this.cartLists[locationInCart].netAmount = amount.amount
      this.cartLists[locationInCart].totalAmount = amount.amount
      this.cartLists[locationInCart].quantitySKU = amount.quantitySKU


      sessionStorage.setItem('cart', JSON.stringify(this.cartLists))
      this.getCartTotal()
    },
    removeFromCart(product) {
      this.trackButtonClick('SelectedItem/RemoveQTY')
      this.showModal = false
      const locationInCart = this.cartLists.findIndex((p) => {
        return p.id === product.id
      })

      let amount = {}

      if (this.cartLists[locationInCart].quantity <= 1) {
        this.cartLists.splice(locationInCart, 1)
      } else {
        this.cartLists[locationInCart].quantity--
        amount = order.updateAmount(
          this.cartLists[locationInCart],
          this.cartLists[locationInCart].quantity
        )

        this.cartLists[locationInCart].price = amount.price
        this.cartLists[locationInCart].netAmount = amount.amount
        this.cartLists[locationInCart].totalAmount = amount.amount
        this.cartLists[locationInCart].quantitySKU = amount.quantitySKU
      }

      sessionStorage.setItem('cart', JSON.stringify(this.cartLists))
      this.getCartTotal()
    },
    deleteAllFromCartList(product) {
      this.showModal = false
      const locationInCart = this.cartLists.findIndex((p) => {
        return p.id === product.id
      })
      this.cartLists.splice(locationInCart, 1)
      sessionStorage.setItem('cart', JSON.stringify(this.cartLists))
      this.getCartTotal()

    },
  },
}
</script>

<style scoped>
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.modal {
  align-items: center;
  justify-content: center;
  height: 35%;
  width: 80%;
  border-radius: 3%;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.modal .modal-body {
  overflow-y: hidden;
}

@media screen and (max-width: 370px) {
  .add-qty-small {
    width: 30px !important;
    height: 30px !important;
    font-size: 10px !important;
    text-align: center;
  }

  .input-small {
    font-size: 10px !important;
  }


}

img.image-inbox {
  position: relative;
  object-fit: cover;
}
</style>
